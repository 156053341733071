import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";


Vue.use(VueRouter);

const routes = [
  {
    path: "/auth/:token/:usu_id/:route",
    name: "Authenticate",
    props: true,
    component: () => import("../views/access/Authenticate.vue")
  },
  {
    path: "/auth/:token/:usu_id",
    name: "Authenticate",
    props: true,
    component: () => import("../views/access/Authenticate.vue")
  },
  {
    path: "/notfound",
    name: "NotFound",
    component: () => import("../views/access/NotFound.vue")
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () => import("../views/access/Unauthorized.vue")
  },
  {
    path: "/imports",
    name: "imports",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/imports/Imports.vue"),
  },
  {
    path: "/surveys",
    name: "surveys",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/surveys/Surveys.vue"),
  },
  {
    path: "/results",
    name: "results",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/results/Results.vue"),
  },
  {
    path: "/questionnairesResults",
    name: "questionnairesResults",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/results/QuestionnairesResults.vue"),
  },
  {
    path: "/alerts",
    name: "Alerts",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/alerts/Alerts.vue"),
  },
  {
    path: "/alertsQuestionnaries",
    name: "AlertsQuestionnaries",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/alerts/AlertsQuestionnaries.vue"),
  },
  {
    path: "/rules",
    name: "Rules",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/alerts/Rules/Rules.vue"),
  },
  {
    path: "/analyticsResults/:Cst_Id",
    name: "AnalyticsResults",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/analytics/Analytics.vue"),
  }, 
  {
    path: "/ResultsAdmin",
    name: "ResultsAdmin",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/results/ResultsAdmin.vue"),
  },
  {
    path: "/pricesList",
    name: "PricesList",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Prices/PricesList.vue"),
  },
  {
    path: "/Prices",
    name: "Prices",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Prices/Prices.vue"),
  },
  {
    path: "/MassiveEditPrices",
    name: "MassiveEditPrices",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Prices/MassiveEditPrices.vue"),
  },
  {
    path: "/Products",
    name: "Products",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Products/Products.vue"),
  },
  {
    path: "/ProductPresentation",
    name: "ProductPresentation",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Products/Presentations.vue"),
  },
  {
    path: "/freight",
    name: "Freight",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Freight/Freight.vue"),
  },
  {
    path: "/FreightDetail",
    name: "FreightDetail",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Freight/FreightDetail.vue"),
  },
  {
    path: "/ResultsGroupInstallations",
    name: "ResultsGroupInstallations",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/results/ResultsGroupInstallations.vue"),
  },
  {
    path: "/CollectionResults",
    name: "CollectionResults",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/results/CollectionResults.vue"),
  },
  {
    path: "/Diffusion",
    name: "Diffusion",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/diffusion/Diffusion.vue"),
  },
  {
    path: "/Weighing",
    name: "Weighing",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/weighing/Weighing.vue"),
  },
  /* Campañas */
  {
    path: "/campaign", 
    name: "campaign",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Campaigns/campaign.vue"),
    props: true,
  },
  {
    path: "/resultadosCampaign", 
    name: "resultadosCampaign",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Campaigns/ResultadosCampaign/resultadosCampaign.vue"),
    props: true,
  },
  /*{
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },*/
  {
    path: "/installations",
    name: "installations",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/installations/Installations.vue"),
  },
  {
    path: "/installationsRoadmap",
    name: "installationsRoadmap",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/installations/InstallationsRoadmap.vue"),
  },
  {
    path: "/",
    name: "home",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Home.vue"),
    props: true,
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/Logout.vue"),
  },
  {
    path: "/typeQuestionnaires",
    name: "typeQuestionnaires",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/questionnaires/TypeQuestionnaires.vue")
  },
  {
    path: "/Roadmap",
    name: "Roadmap",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/roadmap/Roadmap.vue")
  },
  {
    path: "/createRoadmap",
    name: "CreateRoadmap",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/roadmap/Create.vue")
  },
  {
    path: "/editRoadmap/:idRuta",
    name: "EditRoadmap",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/roadmap/Edit.vue")
  },
  {
    path: "/RoadmapSummary",
    name: "RoadmapSummary",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/roadmap/RoadmapSummary.vue")
  },
  {
    path: "/channels",
    name: "channels",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/settings/channels/Channels.vue")
  },
  {
    path: "/schedule",
    name: "schedule",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/schedule/Schedule.vue")
  },
  {
    path: "/questionCategories",
    name: "questionCategories",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/questionCategories/QuestionCategories.vue")
  },
  {
    path: "/installationsTypes",
    name: "installationsTypes",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/installationsTypes/InstallationsTypes.vue")
  },
  {
    path: "/adminPollsters",
    name: "adminPollsters",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pollsters/AdminPollsters.vue")
  },
  {
    path: "/roadmap",
    name: "roadmap",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pollsters/Roadmap.vue"),
  },
  {
    path: "/adminQuestionnaires",
    name: "adminQuestionnaires",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/questionnaires/AdminQuestionnaires.vue")
  },
  {
    path: "/associatedQuestions/:id?",
    name: "associatedQuestions",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/questionnaires/AssociatedQuestions.vue")
  },
  {
    path: "/AdminCustomers", ///:id?/:action?/:survey?/:pollster?",
    name: "AdminCustomers",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/customers/AdminCustomers.vue")
  },
  {
    path: "/createEditCustomer/:id?/:action?/:survey?/:pollster?",
    name: "createEditCustomer",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/customers/CreateEditCustomer.vue")
  },
  {
    path: "/createEditQuestionnaire/:id?/:action?/:survey?/:pollster?",
    name: "createEditQuestionnaire",
    props: true,
    meta: {
      requiresAuth: true,
      reload: true,
    },
    component: () => import("../views/questionnaires/CreateEditQuestionnaire.vue")
  },
  {
    path: "/createSurvey/:id?/:action?",
    name: "CreateSurvey",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/surveys/CreateSurvey.vue")
  },
  {
    path: "/answers",
    name: "answers",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/answers/AdminAnswers.vue"),
  },
  {
    path: "/answer_survey_detail/:id",
    name: "AnswerSurveyDetail",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/answers/AnswerSurveyDetail.vue"),
  },
  {
    path: "/list_answers_pollster",
    name: "ListAnswersByPollster",
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/answers/ListAnswersByPollster.vue"),
  },
  {
    path: "/holidays",
    name: "holidays",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/settings/holidays/adminHolidays.vue"),
  },
  {
    path: "/bag_quetions",
    name: "BagQuetions",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/questions/AdminBagQuestions.vue"),
  },
  {
    path: "/admin_groups",
    name: "AdminGroups",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/settings/groups/AdminGroups.vue"),
  },
  {
    path: "/questionnaire_group_destiny",
    name: "QuestionnaireGroupDestiny",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/questionnaires/forGroupAndDestino/QuestionnaireGroupDestiny.vue"),

 },
 {
    path: "/questionnaire_group_destiny_random",
    name: "QuestionnaireGroupDestinyRandomRules",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/questionnaires/forGroupAndDestino/Random/RandomRules.vue"),

 },
 {
    path: "/random_detail/:id",
    name: "QuestionnaireGroupDestinyRandomRulesDetails",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/questionnaires/forGroupAndDestino/Random/CreateEdit.vue"),
 },
{
    path: "/user_group",
    name: "UserGroup",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/settings/groups/UserGroup.vue"),
},
{
    path: "/groups_pollsters",
    name: "GroupsPollsters",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pollsters/GroupsPollsters.vue"),
},
{
  path: "/generator",
  name: "Generator",
  meta: {
    requiresAuth: true,
  },
  component: () => import("../views/chatgpt/generatorIa.vue"),
},
{
  path: "/generatorResult/:Cst_Id",
  name: "GeneratorResult",
  meta: {
    requiresAuth: true,
  },
  component: () => import("../views/questionnaires/QuestionnaireResult.vue"),
},
];

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
    //valida si la route existe
    if (to.matched.length > 0) {//si existe

      //verifica si la route requiere login
      if(to.matched.some(record => record.meta.requiresAuth)) {//si requiere login
        if (store.getters.isLoggedIn) {
          next()
          return
        } else {
          router.push({
            name: 'Unauthorized'
          });
        }
      } else {//sino requiere login
        next()
      }
    } else {//sino existe
      router.push({
        name: 'NotFound'
      });
    }
  })

export default router;
