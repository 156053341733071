import { render, staticRenderFns } from "./loader.vue?vue&type=template&id=be65953c&scoped=true"
var script = {}
import style0 from "./loader.vue?vue&type=style&index=0&id=be65953c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be65953c",
  null
  
)

export default component.exports