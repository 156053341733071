import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import router from '../router'
import quickMessage from './modules/quickMessage';
import hardCode from './modules/hardCode';
//import moment from "moment";

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: { quickMessage, hardCode },
  plugins: [createPersistedState()],
  getters : {
    isLoggedIn: state => !!state.user.authToken,
    authStatus: state => state.user.loggedInStatus,
  },
  state: {
    user: {
      Usu_Id: '',
      Emp_Id:'',
      Emp_Nombre: '',
      Emp_Imagen: '',
      name: '',
      loggedInStatus: false,
      authToken: '',
      Usu_Nombre: '',
      Usu_Apellido: '',
      Usu_Imagen: '',
      Roles: [],
      menu: [],
    },
    rules: {
      min: v => v.length >= 8 || 'Min 8 caracteres',
      min1: v => v.length >= 1 || 'Este campo es Requerido',
      required: v => !!v || 'Este dato es Requerido',
      requiredMultiple: v => v.length > 0 || 'Este dato es Requerido',
    },
  },
  mutations: {
    addWebToken: function (state, data) {
      state.user.authToken = data.Use_Token;
      state.user.Usu_Id = data.Usu_Id;
      state.user.Emp_Id = data.Emp_Id;
      state.user.Emp_Nombre = data.Emp_Data.Emp_Nombre;
      state.user.Emp_Imagen = data.Emp_Data.Emp_Imagen;
      state.user.Usu_TieneClientes = data.Usu_TieneClientes;
      state.user.name = data.Usu_Nombre + ' ' + data.Usu_Apellido;
      state.user.Roles = data.Roles;
      state.user.Usu_Nombre = data.Usu_Nombre;
      state.user.Usu_Apellido = data.Usu_Apellido;
      state.user.Usu_Imagen = data.Usu_Imagen;
      state.user.menu = data.menuAdminEncuesta;
      state.user.loggedInStatus = true;
    },
    removeWebToken: function (state) {
      state.user.authToken = '';
      state.user.Usu_Id = '';
      state.user.Emp_Id = '';
      state.user.Emp_Nombre = '';
      state.user.Emp_Imagen = '';
      state.user.name = '';
      state.user.menu = [];
      state.user.loggedInStatus = false;
    },
  },
  actions: {
    login: function (context, data) {
      return Vue.prototype.$http.post(process.env.VUE_APP_LOGIN_URI + 'login/by-token', { Use_Token: data.token, Usu_Id: data.usu_id})

        .then(function (response) {

          if (response.data.status === 'ok') {
            context.commit('addWebToken', response.data.data); // pass the webtoken as payload to the mutation
            console.log(response.data.data);
            let path = router.currentRoute.query?.path ?? ''
            router.push({ path: `/${path}` })
          }
          return response.data;
        });
    },
    logout: function (context) {
      context.commit('removeWebToken');
      router.push({
          path: '/',
      });
    }
  }
})

export default store;